// // src/components/Header.js

// import React, { useState } from 'react';
// import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
// import logo from '../assets/logo-wah.png';

// function Header() {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <header className="bg-gray-900 text-white font-poppins">
//       <div className="container mx-auto flex items-center justify-between py-4 px-6">
//         {/* Logo */}
//         <div className="flex items-center">
//           <img src={logo} alt="Wah Smart Deals Logo" className="h-auto w-40" />
//         </div>

//         {/* Desktop Menu */}
//         <nav className="hidden md:flex space-x-8 items-center">
//           <a href="#home" className="text-white hover:text-yellow-500">
//             Home
//           </a>
//           <a href="#features" className="text-gray-400 hover:text-yellow-500">
//             Features
//           </a>
//           <a href="#about" className="text-gray-400 hover:text-yellow-500">
//             About
//           </a>
//           <a href="#about" className="text-gray-400 hover:text-yellow-500">
//             Support
//           </a>
//           <a href="#about" className="text-gray-400 hover:text-yellow-500">
//             Privacy Policy
//           </a>
//           <button className="bg-yellow-500 text-white font-medium px-4 py-2 rounded-full hover:bg-yellow-600">
//             DOWNLOAD
//           </button>
//         </nav>

//         {/* Mobile Menu Button */}
//         <div className="md:hidden">
//           <button
//             onClick={() => setIsOpen(!isOpen)}
//             className="text-white hover:text-yellow-500 focus:outline-none"
//           >
//             {isOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
//           </button>
//         </div>
//       </div>

//       {/* Mobile Menu */}
//       {isOpen && (
//         <div className="md:hidden bg-gray-800">
//           <nav className="space-y-4 px-6 py-4">
//             <a href="#home" className="block text-white hover:text-yellow-500">
//               Home
//             </a>
//             <a href="#features" className="block text-gray-400 hover:text-yellow-500">
//               Features
//             </a>
//             <a href="#about" className="block text-gray-400 hover:text-yellow-500">
//               About
//             </a>
//             <a href="#about" className="text-gray-400 hover:text-yellow-500">
//             Support
//           </a>
//           <a href="#about" className="text-gray-400 hover:text-yellow-500">
//             Privacy Policy
//           </a>
//             <button className="w-full bg-yellow-500 text-white font-medium py-2 rounded-full hover:bg-yellow-600">
//               DOWNLOAD
//             </button>
//           </nav>
//         </div>
//       )}
//     </header>
//   );
// }

// export default Header;


// src/components/Header.js

// src/components/Header.js

// import React, { useState, useEffect } from 'react';
// import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
// import { Link, useLocation } from 'react-router-dom';
// import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
// import logo from '../assets/logo-wah.png';

// function Header() {
//   const [isOpen, setIsOpen] = useState(false);
//   const [activeSection, setActiveSection] = useState('home');
//   const location = useLocation();

//   useEffect(() => {
//     if (location.pathname === "/support") {
//       setActiveSection("support");
//     } else if (location.pathname === "/privacy-policy") {
//       setActiveSection("privacy-policy");
//     } else {
//       const handleScroll = () => {
//         const sections = ['home', 'features', 'about'];
//         const scrollPosition = window.scrollY + 100;
//         sections.forEach((section) => {
//           const element = document.getElementById(section);
//           if (element && element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition) {
//             setActiveSection(section);
//           }
//         });
//       };
//       window.addEventListener('scroll', handleScroll);
//       return () => {
//         window.removeEventListener('scroll', handleScroll);
//       };
//     }
//   }, [location]);

//   return (
//     <header className="bg-gray-900 text-white font-poppins fixed w-full z-50 shadow-lg">
//       <div className="container mx-auto flex items-center justify-between py-4 px-6">
//         {/* Logo */}
//         <div className="flex items-center cursor-pointer" onClick={() => scroll.scrollToTop()}>
//           <img src={logo} alt="Wah Smart Deals Logo" className="h-auto w-40" />
//         </div>

//         {/* Desktop Menu */}
//         <nav className="hidden md:flex space-x-8 items-center">
//           <ScrollLink
//             to="home"
//             smooth={true}
//             duration={500}
//             className={`cursor-pointer ${activeSection === 'home' ? 'text-yellow-500' : 'text-gray-400'} hover:text-yellow-500`}
//             onClick={() => setActiveSection("home")}
//           >
//             Home
//           </ScrollLink>
//           <ScrollLink
//             to="features"
//             smooth={true}
//             duration={500}
//             className={`cursor-pointer ${activeSection === 'features' ? 'text-yellow-500' : 'text-gray-400'} hover:text-yellow-500`}
//             onClick={() => setActiveSection("features")}
//           >
//             Benefits
//           </ScrollLink>
//           <ScrollLink
//             to="about"
//             smooth={true}
//             duration={500}
//             className={`cursor-pointer ${activeSection === 'about' ? 'text-yellow-500' : 'text-gray-400'} hover:text-yellow-500`}
//             onClick={() => setActiveSection("about")}
//           >
//             About
//           </ScrollLink>
//           <Link
//             to="/support"
//             className={`text-gray-400 hover:text-yellow-500 ${activeSection === 'support' ? 'text-yellow-500' : ''}`}
//             onClick={() => setActiveSection("support")}
//           >
//             Support
//           </Link>
//           <Link
//             to="/privacy-policy"
//             className={`text-gray-400 hover:text-yellow-500 ${activeSection === 'privacy-policy' ? 'text-yellow-500' : ''}`}
//             onClick={() => setActiveSection("privacy-policy")}
//           >
//             Privacy Policy
//           </Link>
//           <button className="bg-yellow-500 text-white font-medium px-4 py-2 rounded-full hover:bg-yellow-600">
//             DOWNLOAD
//           </button>
//         </nav>

//         {/* Mobile Menu Button */}
//         <div className="md:hidden">
//           <button
//             onClick={() => setIsOpen(!isOpen)}
//             className="text-white hover:text-yellow-500 focus:outline-none"
//           >
//             {isOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
//           </button>
//         </div>
//       </div>

//       {/* Mobile Menu */}
//       {isOpen && (
//         <div className="md:hidden bg-gray-800">
//           <nav className="space-y-4 px-6 py-4">
//             <ScrollLink
//               to="home"
//               smooth={true}
//               duration={500}
//               onClick={() => { setIsOpen(false); setActiveSection("home"); }}
//               className="block text-white hover:text-yellow-500 cursor-pointer"
//             >
//               Home
//             </ScrollLink>
//             <ScrollLink
//               to="features"
//               smooth={true}
//               duration={500}
//               onClick={() => { setIsOpen(false); setActiveSection("features"); }}
//               className="block text-gray-400 hover:text-yellow-500 cursor-pointer"
//             >
//               Benefits
//             </ScrollLink>
//             <ScrollLink
//               to="about"
//               smooth={true}
//               duration={500}
//               onClick={() => { setIsOpen(false); setActiveSection("about"); }}
//               className="block text-gray-400 hover:text-yellow-500 cursor-pointer"
//             >
//               About
//             </ScrollLink>
//             <Link
//               to="/support"
//               className={`block text-gray-400 hover:text-yellow-500 ${activeSection === 'support' ? 'text-yellow-500' : ''}`}
//               onClick={() => { setIsOpen(false); setActiveSection("support"); }}
//             >
//               Support
//             </Link>
//             <Link
//               to="/privacy-policy"
//               className={`block text-gray-400 hover:text-yellow-500 ${activeSection === 'privacy-policy' ? 'text-yellow-500' : ''}`}
//               onClick={() => { setIsOpen(false); setActiveSection("privacy-policy"); }}
//             >
//               Privacy Policy
//             </Link>
//             <button className="w-full bg-yellow-500 text-white font-medium py-2 rounded-full hover:bg-yellow-600">
//               DOWNLOAD
//             </button>
//           </nav>
//         </div>
//       )}
//     </header>
//   );
// }

// export default Header;


// src/components/Header.js

import React, { useState, useEffect } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../assets/logo-wah.png';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/support") {
      setActiveSection("support");
    } else if (location.pathname === "/privacy-policy") {
      setActiveSection("privacy-policy");
    } else {
      const handleScroll = () => {
        const sections = ['home', 'features', 'about'];
        const scrollPosition = window.scrollY + 100;
        sections.forEach((section) => {
          const element = document.getElementById(section);
          if (element && element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition) {
            setActiveSection(section);
          }
        });
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [location]);

  const handleNavigation = (section) => {
    if (location.pathname !== "/") {
      navigate("/", { replace: true });
      setTimeout(() => {
        scroll.scrollTo(section === "home" ? 0 : document.getElementById(section).offsetTop, {
          smooth: true,
          duration: 500,
        });
      }, 100);
    } else {
      scroll.scrollTo(section === "home" ? 0 : document.getElementById(section).offsetTop, {
        smooth: true,
        duration: 500,
      });
    }
  };

  return (
    <header className="bg-black text-white font-poppins fixed w-full z-50 shadow-lg">
      <div className="container mx-auto flex items-center justify-between py-4 px-6">
        {/* Logo */}
        <div className="flex items-center cursor-pointer" onClick={() => handleNavigation("home")}>
          <img src={logo} alt="Wah Smart Deals Logo" className="h-auto w-40" />
        </div>

        {/* Desktop Menu */}
        <nav className="hidden md:flex space-x-8 items-center">
          <span
            onClick={() => handleNavigation("home")}
            className={`cursor-pointer ${activeSection === 'home' ? 'text-[#F7930D]' : 'text-gray-400'} hover:text-[#F7930D]`}
          >
            Home
          </span>
          <span
            onClick={() => handleNavigation("features")}
            className={`cursor-pointer ${activeSection === 'features' ? 'text-[#F7930D]' : 'text-gray-400'} hover:text-[#F7930D]`}
          >
            Benefits
          </span>
          <span
            onClick={() => handleNavigation("about")}
            className={`cursor-pointer ${activeSection === 'about' ? 'text-[#F7930D]' : 'text-gray-400'} hover:text-[#F7930D]`}
          >
            About
          </span>
          <Link
            to="/support"
            className={`text-gray-400 hover:text-[#F7930D] ${activeSection === 'support' ? 'text-[#F7930D]' : ''}`}
            onClick={() => setActiveSection("support")}
          >
            Support
          </Link>
          <Link
            to="/privacy-policy"
            className={`text-gray-400 hover:text-[#F7930D] ${activeSection === 'privacy-policy' ? 'text-[#F7930D]' : ''}`}
            onClick={() => setActiveSection("privacy-policy")}
          >
            Privacy Policy
          </Link>
          <button className="bg-[#F7930D] text-white font-medium px-4 py-2 rounded-full hover:bg-[#F7930D]">
            DOWNLOAD
          </button>
        </nav>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white hover:text-[#F7930D] focus:outline-none"
          >
            {isOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-gray-800">
          <nav className="space-y-4 px-6 py-4">
            <span
              onClick={() => { setIsOpen(false); handleNavigation("home"); }}
              className="block text-white hover:text-yellow-500 cursor-pointer"
            >
              Home
            </span>
            <span
              onClick={() => { setIsOpen(false); handleNavigation("features"); }}
              className="block text-gray-400 hover:text-yellow-500 cursor-pointer"
            >
              Benefits
            </span>
            <span
              onClick={() => { setIsOpen(false); handleNavigation("about"); }}
              className="block text-gray-400 hover:text-yellow-500 cursor-pointer"
            >
              About
            </span>
            <Link
              to="/support"
              className={`block text-gray-400 hover:text-yellow-500 ${activeSection === 'support' ? 'text-yellow-500' : ''}`}
              onClick={() => { setIsOpen(false); setActiveSection("support"); }}
            >
              Support
            </Link>
            <Link
              to="/privacy-policy"
              className={`block text-gray-400 hover:text-[#F7930D] ${activeSection === 'privacy-policy' ? 'text-[#F7930D]' : ''}`}
              onClick={() => { setIsOpen(false); setActiveSection("privacy-policy"); }}
            >
              Privacy Policy
            </Link>
            <button className="w-full bg-[#F7930D] text-white font-medium py-2 rounded-full hover:bg-yellow-600">
              DOWNLOAD
            </button>
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;
