// src/pages/About.js

import React from 'react';
import featuresImg from '../assets/features.png';

function About() {
  return (
    <section className="bg-black text-white font-poppins py-16">
      <div className="container mx-auto px-6 md:px-12 lg:px-16 flex flex-col lg:flex-row items-center justify-center space-y-12 lg:space-y-0 lg:space-x-8">
        
        {/* Steps on the Left (Desktop) */}
        <div className="flex flex-col items-end text-right space-y-8 w-60">
          <Step number="1" title="Download" description="WahSmartDeals App from Store." />
          <Step number="2" title="Subscribe" description="Subscribe to available plans." />
          <Step number="3" title="10x Cash Rewards" description="Get 10x cash rewards in your digital wallet." />
        </div>

        {/* Central Image */}
        <div className="flex-shrink-0">
          <img src={featuresImg} alt="Features" className="mx-auto w-64 lg:w-auto h-[30rem]" />
        </div>

        {/* Steps on the Right (Desktop) */}
        <div className="flex flex-col items-start text-left space-y-8 w-64">
          <Step number="4" title="Generate Coupons" description="Browse your favourite deals and generate coupon." />
          <Step number="5" title="Redeem Coupons" description="Redeem Coupons during checkou and enjoy instant savings on your purchases." />
          <Step number="6" title="Share & Refer" description="Share & refer your success story with friends and family and get more bonus coupons." />
        </div>
      </div>
    </section>
  );
}

// Step Component
function Step({ number, title, description }) {
  return (
    <div className="flex flex-col items-center text-center space-y-2">
      {/* Circle with Step Number */}
      <div className="flex items-center justify-center w-12 h-12 bg-[#F7930D] text-white font-bold rounded-full text-lg mb-2">
        {number}
      </div>
      
      {/* Step Text */}
      <div>
        <h3 className="text-xl font-semibold">{title}</h3>
        <p className="text-gray-400 text-sm mt-1">{description}</p>
      </div>
    </div>
  );
}

export default About;
