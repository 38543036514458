// src/pages/Support.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import mailImage from '../assets/mail.png';

function Support() {
  return (
    <div className="text-white text-center py-16 flex flex-col items-center justify-center min-h-screen bg-gray-900">
      {/* Title */}
      <h1 className="text-3xl lg:text-4xl font-bold text-white">Contact Us</h1>

      {/* Mail Image */}
      <img src={mailImage} alt="Mail" className="w-[300px] h-auto" />

      {/* Email Contact */}
      <div className="flex items-center space-x-2 mb-4">
        <FontAwesomeIcon icon={faEnvelope} className="w-6 h-6 text-yellow-500" />
        <span className="text-lg font-medium">Email: info@wahsmartdeals</span>
      </div>

      {/* WhatsApp Contact */}
      <div className="flex items-center space-x-2">
        <FontAwesomeIcon icon={faPhone} className="w-6 h-6 text-green-500" />
        <span className="text-lg font-medium">Phone: +1 (312) 852 0414</span>
      </div>
    </div>
  );
}

export default Support;
