// // src/App.js
// import React from 'react';
// import Header from './components/Header';
// import Home from './pages/Home';
// import Features from './pages/Features';
// import About from './pages/About';
// import Get from './pages/Get';

// function App() {
//   return (
//     <div className="bg-gray-900 min-h-screen">
//       <Header />
//       <Home />
//       <Features />
//       <About />
//       <Get />
//     </div>
//   );
// }

// export default App;


// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Features from './pages/Features';
import About from './pages/About';
import Get from './pages/Get';
import Support from './pages/Support';
import Privacy from './pages/Privacy';
import Disclaimer from './pages/Disclaimer';

function App() {
  return (
    <Router>
      <div className="bg-black min-h-screen">
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div id="home"><Home /></div>
                <div id="features"><Features /></div>
                <div id="about"><About /></div>
                <Get />
              </>
            }
          />
          <Route path="/support" element={<Support />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
