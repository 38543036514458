// src/pages/Privacy.js

import React from 'react';

function Privacy() {
  return (
    <div className="text-white py-16 px-8 md:px-16 lg:px-32">
      {/* Main Heading */}
      <h1 className="text-3xl font-bold mb-8 mt-20 text-left">Privacy Policy</h1>
      
      {/* Privacy Policy Content */}
      <div className="text-white-400 space-y-6 text-justify">
        
        <p>WahSmartDeals LLC ("we," "our," or "us") respects your privacy and is committed to protecting your personal data. This Privacy Policy outlines how we collect, use, and share information through our mobile application ("App") and website ("Website"). By using our App or Website, you agree to the practices outlined in this policy.</p>

        <h2 className="text-lg font-semibold mt-4">1. Information We Collect</h2>
        <ul className="list-disc list-inside">
          <li><strong>Personal Information:</strong> Your name, email address, phone number, billing information, etc., when you sign up.</li>
          <li><strong>Transaction Data:</strong> Details about transactions, rewards, deals redeemed, and payments made.</li>
          <li><strong>Device Information:</strong> IP address, device ID, operating system, browser type, and app usage statistics.</li>
          <li><strong>Location Information:</strong> Geolocation data (with your consent) to offer location-based deals.</li>
          <li><strong>Cookies and Tracking:</strong> We use cookies and similar technologies to improve user experience. See our Cookie Policy for more information.</li>
          <li><strong>Push Notifications:</strong> We may send you notifications about your account or features. You can opt-out in your device settings.</li>
        </ul>

        <h2 className="text-lg font-semibold mt-4">2. How We Use Your Information</h2>
        <ul className="list-disc list-inside">
          <li><strong>To Provide Services:</strong> Create accounts, credit rewards, and facilitate transactions.</li>
          <li><strong>To Improve Our Platform:</strong> Analyze usage trends to enhance the App and Website.</li>
          <li><strong>To Communicate with You:</strong> Send transactional emails, updates, and relevant promotions.</li>
          <li><strong>To Provide Personalized Offers:</strong> With your consent, recommend deals based on location and transaction data.</li>
          <li><strong>To Ensure Security:</strong> Monitor for suspicious activity to keep the platform secure.</li>
        </ul>

        <h2 className="text-lg font-semibold mt-4">3. How We Share Your Information</h2>
        <p>We do not sell your personal data. However, we may share information with:</p>
        <ul className="list-disc list-inside">
          <li><strong>Vendors:</strong> Limited personal and transaction information to facilitate deal redemptions.</li>
          <li><strong>Service Providers:</strong> Third-party providers for payments, data storage, and analytics.</li>
          <li><strong>For Legal Compliance:</strong> If required by law or to prevent fraud.</li>
          <li><strong>Business Transfers:</strong> In mergers or acquisitions, your data may be transferred.</li>
          <li><strong>Google Maps API:</strong> With your consent, location data may be shared with Google Maps to improve location accuracy.</li>
        </ul>

        <h2 className="text-lg font-semibold mt-4">4. How We Protect Your Data</h2>
        <p>We use encryption and secure storage to protect your data. However, no system is completely secure. We strive to protect your data but cannot guarantee absolute security.</p>

        <h2 className="text-lg font-semibold mt-4">5. Your Privacy Rights</h2>
        <p>You have the following rights regarding your personal data:</p>
        <ul className="list-disc list-inside">
          <li><strong>Access:</strong> Request access to the data we hold.</li>
          <li><strong>Correction:</strong> Request correction of inaccurate or incomplete information.</li>
          <li><strong>Deletion:</strong> Request deletion of your data, subject to legal obligations.</li>
          <li><strong>Opt-Out of Marketing:</strong> Unsubscribe from promotional communications by following instructions in the emails or SMS.</li>
        </ul>
        <p>To exercise these rights, contact us at <strong>info@wahsmartdeals.com</strong>.</p>

        <h2 className="text-lg font-semibold mt-4">6. Data Retention</h2>
        <p>We retain your data as long as necessary for the purposes outlined or as required by law. Once no longer needed, it is securely deleted or anonymized.</p>

        <h2 className="text-lg font-semibold mt-4">7. Children's Privacy</h2>
        <p>WahSmartDeals is not intended for individuals who are minors. If you believe we’ve collected data from a child, contact us to take appropriate action.</p>

        <h2 className="text-lg font-semibold mt-4">8. Cookies and Tracking Technologies</h2>
        <p>We may use cookies and similar technologies to collect information. Third-party service providers may use these technologies for analytics or advertising. Refer to our Cookie Notice for details.</p>

        <h2 className="text-lg font-semibold mt-4">9. External Links</h2>
        <p>WahSmartDeals may contain links to third-party websites or services. We are not responsible for their privacy practices or content. Review their policies before sharing personal data.</p>

        <h2 className="text-lg font-semibold mt-4">10. Affiliates and Third-Party Relationships</h2>
        <p>We may include affiliate links or promotions for third-party services. Your interactions with them are governed by their privacy policies.</p>

        <h2 className="text-lg font-semibold mt-4">11. International Data Transfers</h2>
        <p>If you are located outside the U.S., your data may be transferred and processed in the U.S. or other countries where our service providers are located.</p>

        <h2 className="text-lg font-semibold mt-4">12. Your Rights under the General Data Protection Regulation (UK law)</h2>
        <p>We undertake to respect the confidentiality of your Personal Data and to guarantee you can exercise your rights. You have rights under GDPR if within the EU, including:</p>
        <ul className="list-disc list-inside">
          <li><strong>Request access:</strong> The right to access, update or delete the information we have on you.</li>
          <li><strong>Request correction:</strong> Correction of incomplete or inaccurate information.</li>
          <li><strong>Object to processing:</strong> The right to object to our processing based on legitimate interest.</li>
          <li><strong>Request erasure:</strong> Ask us to delete or remove Personal Data when no longer needed.</li>
          <li><strong>Request transfer:</strong> Receive Personal Data in a machine-readable format.</li>
          <li><strong>Withdraw consent:</strong> Withdraw consent for data usage.</li>
        </ul>

        <h2 className="text-lg font-semibold mt-4">13. CCPA/CPRA Privacy Notice</h2>
        <p>This privacy notice for California residents supplements the information contained in our Privacy Policy, as required under CCPA/CPRA.</p>

        <h2 className="text-lg font-semibold mt-4">14. Changes to This Privacy Policy</h2>
        <p>WahSmartDeals reserves the right to update this Privacy Policy at any time. Changes will be effective upon posting. Please review periodically to stay informed.</p>

        <h2 className="text-lg font-semibold mt-4">15. Contact Us</h2>
        <p>If you have any questions or concerns about this policy, you can contact us at:</p>
        <ul className="list-disc list-inside">
          <li>Email: info@wahsmartdeals.com</li>
          <li>Phone: 312-852-0414</li>
          <li>Address: 13235 Affirmed Avenue, Frisco, TX 75035</li>
        </ul>

        <h2 className="text-lg font-semibold mt-4">16. Governing Law</h2>
        <p>This Privacy Policy is governed by the laws of Texas, without regard to conflict of law provisions.</p>
      </div>
    </div>
  );
}

export default Privacy;
