// src/pages/Disclaimer.js

import React from 'react';

function Disclaimer() {
  return (
    <div className="text-white py-16 px-8 md:px-16 lg:px-32">
      {/* Main Heading */}
      <h1 className="text-3xl font-bold mb-8 text-left">Disclaimer</h1>
      
      {/* Disclaimer Content */}
      <div className="text-white space-y-6">
        
        <p>This Disclaimer ("Disclaimer") applies to your use of the WahSmartDeals mobile application (the "App") and the http://www.wahsmartdeals.com website. All information on the site and app is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site or our mobile application.</p>
        
        <p><strong>UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.</strong> By accessing or using the App, you agree to the terms outlined in this Disclaimer. If you do not agree with these terms, please do not use the App.</p>

        {/* Section 1 */}
        <h2 className="text-lg font-semibold mt-4">1. 10x Monthly Cash Rewards</h2>
        <ul className="list-disc list-inside">
          <li>WahSmartDeals operates using a virtual currency system. Any rewards, cash bonuses, or other credits added to your digital wallet are CLOSED Virtual Currency and have no real cash value.</li>
          <li>The Virtual Currency is used solely within the app to generate discounts on deals and cannot be converted to real money, transferred, or withdrawn outside of the app.</li>
          <li><strong>Expiration Policy:</strong> Cash Rewards / Virtual Currency must be used within the current billing cycle. Unused rewards expire at the end of the billing cycle and do not roll over to the next cycle.</li>
        </ul>

        {/* Section 2 */}
        <h2 className="text-lg font-semibold mt-4">2. Coupon Generation and Redemption</h2>
        <ul className="list-disc list-inside">
          <li>You may generate digital coupons using your cash rewards/ Virtual Currency to redeem discounts on deals posted by third-party vendors.</li>
          <li>Coupons are valid for the specific terms outlined by the vendors. WahSmartDeals does not guarantee the availability or fulfillment of any vendor offers.</li>
          <li>It is the vendor's responsibility to honor and fulfill the coupon and deal. WahSmartDeals is not liable for any issues related to the redemption of the coupon.</li>
        </ul>

        {/* Section 3 */}
        <h2 className="text-lg font-semibold mt-4">3. Limitation of Liability</h2>
        <ul className="list-disc list-inside">
          <li><strong>No Warranties:</strong> WahSmartDeals is provided on an "as is" and "as available" basis, without warranties of any kind. The Company does not guarantee that the App will always be available or error-free.</li>
          <li><strong>Limited Liability:</strong> To the fullest extent permitted by law, WahSmartDeals and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of the App, Virtual Currency, or any deals offered by vendors.</li>
          <li><strong>No Responsibility for Vendor Actions:</strong> WahSmartDeals is not responsible for the actions, offers, or services provided by vendors. Any disputes arising from vendor deals should be resolved directly with the vendor.</li>
        </ul>

        {/* Section 4 */}
        <h2 className="text-lg font-semibold mt-4">4. No Guarantee of Savings</h2>
        <ul className="list-disc list-inside">
          <li>The deals and rewards provided by WahSmartDeals are intended to offer discount and savings opportunities but are not guaranteed to meet individual customer needs or preferences.</li>
          <li>WahSmartDeals makes no guarantees regarding the specific savings, availability, or quality of deals available on the platform.</li>
        </ul>

        {/* Section 5 */}
        <h2 className="text-lg font-semibold mt-4">5. Payment and Refunds</h2>
        <ul className="list-disc list-inside">
          <li>All payments for subscriptions are final and non-refundable. Any fees paid to WahSmartDeals, including subscription fees, do not entitle you to a refund under any circumstances.</li>
          <li><strong>Vendor Payments:</strong> WahSmartDeals does not process payments to vendors. Any payments due to vendors during coupon redemption must be paid directly to the vendor, in accordance with the deal terms.</li>
        </ul>

        {/* Section 6 */}
        <h2 className="text-lg font-semibold mt-4">6. No Legal or Financial Advice</h2>
        <ul className="list-disc list-inside">
          <li>WahSmartDeals does not provide legal, financial, or tax advice. The information provided within the App is for general informational purposes only.</li>
          <li>You are encouraged to consult with your own legal or financial professionals for advice specific to your situation.</li>
        </ul>

        {/* Section 7 */}
        <h2 className="text-lg font-semibold mt-4">7. External Links Disclaimer</h2>
        <ul className="list-disc list-inside">
          <li>The WahSmartDeals app or website may contain links to third-party websites or services that are not owned or controlled by WahSmartDeals.</li>
          <li><strong>No Endorsement:</strong> WahSmartDeals does not endorse, monitor, or have any control over third-party websites, their content, or their services. We are not responsible for any harm, loss, or damage caused by third-party content, services, or websites.</li>
          <li><strong>Use at Your Own Risk:</strong> By clicking on any third-party links, you do so at your own risk, and WahSmartDeals is not liable for any issues that arise from your use of those external websites.</li>
        </ul>

        {/* Section 8 */}
        <h2 className="text-lg font-semibold mt-4">8. Affiliates Disclaimer</h2>
        <ul className="list-disc list-inside">
          <li><strong>Affiliate Links:</strong> WahSmartDeals may partner with third parties and receive compensation through affiliate programs, sponsorships, or advertising partnerships.</li>
          <li><strong>Disclosure:</strong> Some deals, offers, or promotions displayed on the app may be part of an affiliate marketing relationship, where WahSmartDeals earns commissions when users purchase products or services through affiliate links. These commissions do not influence the pricing or availability of deals to you.</li>
        </ul>

        {/* Section 9 */}
        <h2 className="text-lg font-semibold mt-4">9. Testimonials Disclaimer</h2>
        <ul className="list-disc list-inside">
          <li>Any testimonials, reviews, or success stories featured on WahSmartDeals represent the experiences of individual users and may not be typical of all users. Testimonials are provided for informational purposes only and should not be considered as guarantees of future results.</li>
          <li>WahSmartDeals makes no assurances that any user will experience the same level of satisfaction or success as expressed in testimonials or reviews.</li>
        </ul>

        {/* Section 10 */}
        <h2 className="text-lg font-semibold mt-4">10. Changes to Disclaimer</h2>
        <p>WahSmartDeals reserves the right to modify or update this Disclaimer at any time. Any changes will be effective immediately upon posting within the App. Continued use of the App constitutes your acceptance of any changes.</p>

        {/* Section 11 */}
        <h2 className="text-lg font-semibold mt-4">11. Governing Law</h2>
        <p>This Disclaimer shall be governed by and construed in accordance with the laws of the State of Texas, without regard to its conflict of laws provisions.</p>

        {/* Section 12 */}
        <h2 className="text-lg font-semibold mt-4">12. Contact Information</h2>
        <p>If you have any questions about this Disclaimer or need further clarification, please contact WahSmartDeals at:</p>
        <ul className="list-disc list-inside">
          <li>Email: info@wahsmartdeals.com</li>
          <li>Phone: 312-852-0414</li>
          <li>Address: 13235 Affirmed Avenue, Frisco, TX 75035</li>
        </ul>

      </div>
    </div>
  );
}

export default Disclaimer;
