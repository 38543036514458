// // src/pages/Get.js

// import React from 'react';
// import { Link } from 'react-router-dom';
// import wahTile from '../assets/wah-tile.png';
// import frBg from '../assets/fr-bg.png';
// import googlePlayBadge from '../assets/google_play-badge.png';
// import appStoreBadge from '../assets/app_store-badge.png';

// function Get() {
//   return (
//     <section 
//       className="bg-cover bg-center bg-black text-white font-poppins min-h-screen flex flex-col items-center justify-center space-y-6"
//       // style={{ backgroundImage: `url(${frBg})` }}
//     >
//       {/* Wah Tile Icon */}
//       <img src={wahTile} alt="WAH Tile" className="w-20 h-20 mb-4" />

//       {/* Title Text */}
//       <h1 className="text-3xl lg:text-4xl font-bold">
//         Get <span className="text-yellow-500 font-PoetsenOne">WAH!</span> now
//       </h1>

//       {/* Subtitle Text */}
//       <p className="text-gray-400 text-center text-sm lg:text-base mb-4">
//         Everything included for Android & iOS
//       </p>

//       {/* Store Badges */}
//       <div className="flex space-x-4">
//         <img src={appStoreBadge} alt="Download on the App Store" className="w-40 h-auto" />
//         <img src={googlePlayBadge} alt="Get it on Google Play" className="w-40 h-auto" />
//       </div>

//       {/* Footer Text */}
//       <footer className="mt-6 text-gray-500 text-xs text-center">
//         <span className="font-semibold text-white font-PoetsenOne">WAH!</span> © All rights Reserved
//         <div className="mt-4 space-x-4 text-gray-400">
//           <Link to="/support" className="hover:text-yellow-500">Contact Us</Link>
//           <span>|</span>
//           <Link to="/privacy-policy" className="hover:text-yellow-500">Privacy Policy</Link>
//           <span>|</span>
//           <Link to="/disclaimer" className="hover:text-yellow-500">Disclaimer</Link>
//         </div>
//       </footer>
//     </section>
//   );
// }

// export default Get;


// src/pages/Get.js

import React from 'react';
import { Link } from 'react-router-dom';
import wahTile from '../assets/wah-tile.png';
import frBg from '../assets/fr-bg.png';
import googlePlayBadge from '../assets/google_play-badge.png';
import appStoreBadge from '../assets/app_store-badge.png';
import logoWah from '../assets/logo-wah.png';

function Get() {
  return (
    <section 
      className="bg-cover bg-center bg-black text-white font-poppins min-h-screen flex flex-col items-center justify-center space-y-6"
      // style={{ backgroundImage: `url(${frBg})` }}
    >
      {/* Wah Tile Icon
      <img src={wahTile} alt="WAH Tile" className="w-20 h-20 mb-4" /> */}

      {/* Title Text */}
      <h1 className="text-3xl lg:text-4xl font-bold flex items-center">
        Get 
        <img src={logoWah} alt="WAH Logo" className="w-auto h-20 mx-2" /> {/* Adjust the width and height as needed */}
        Now
      </h1>

      {/* Subtitle Text */}
      <p className="text-gray-400 text-center text-sm lg:text-base mb-4">
        Everything included for Android & iOS
      </p>

      {/* Store Badges */}
      <div className="flex space-x-4">
        <img src={appStoreBadge} alt="Download on the App Store" className="w-40 h-auto" />
        <img src={googlePlayBadge} alt="Get it on Google Play" className="w-40 h-auto" />
      </div>

      {/* Footer Text */}
      <footer className="mt-6 text-gray-500 text-xs text-center">
        <span className="font-semibold text-white font-PoetsenOne font-style: italic">WAH!</span> © All rights Reserved
        <div className="mt-4 space-x-4 text-gray-400">
          <Link to="/support" className="hover:text-yellow-500">Contact Us</Link>
          <span>|</span>
          <Link to="/privacy-policy" className="hover:text-yellow-500">Privacy Policy</Link>
          <span>|</span>
          <Link to="/disclaimer" className="hover:text-yellow-500">Disclaimer</Link>
        </div>
      </footer>
    </section>
  );
}

export default Get;
