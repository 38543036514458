// // src/pages/Home.js

// import React from 'react';
// import bgHero from '../assets/bg-hero.png';
// import deviceHero from '../assets/device-hero.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';

// function Home() {
//   return (
//     <section className="bg-gray-900 text-white font-poppins">
      
//       {/* Main Hero Section */}
//       <div 
//         className="relative min-h-screen flex items-center"
//         style={{
//           backgroundImage: `url(${bgHero})`,
//           backgroundPosition: 'top center',
//           backgroundSize: 'cover',
//           backgroundRepeat: 'no-repeat'
//         }}
//       >
//         <div className="container mx-auto px-4 md:px-12 lg:px-16 flex flex-col items-center md:flex-row">
          
//           {/* Left Section - Text */}
//           <div className="flex-1 text-center md:text-left md:pr-12 mt-6 md:mt-0">
//             <h1 className="text-[36px] md:text-7xl font-bold leading-tight mb-2">
//               <span className="text-yellow-500">Smart</span><br />
//               <span className="text-white">Fast</span><br />
//               <span className="text-white">Analytics</span>
//             </h1>
//           </div>
          
//           {/* Right Section - Device Image (hidden on mobile) */}
//           <div className="flex-1 relative mt-4 md:mt-0 flex justify-center md:justify-end hidden md:block">
//             <img
//               src={deviceHero}
//               alt="Device Hero"
//               className="relative w-max max-w-xs md:max-w-md lg:max-w-lg"
//             />
//           </div>
//         </div>
//       </div>

//       {/* App Store Section */}
//       <div className="container mx-auto px-4 md:px-12 lg:px-16 py-8 md:py-12 text-center flex flex-col items-center">
//         <p className="text-sm md:text-lg font-semibold mb-4 md:mb-6 text-gray-300">
//           Everything included for <span className="font-bold text-white">Android & iOS</span>
//         </p>
        
//         <div className="flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-4">
//           {/* App Store Button */}
//           <a
//             href="#"
//             className="inline-flex items-center justify-center w-48 h-12 md:w-56 md:h-14 bg-yellow-500 text-black font-medium rounded-lg shadow-md hover:bg-yellow-600 transition-colors text-center"
//           >
//             <FontAwesomeIcon icon={faApple} className="w-5 h-5 md:w-6 md:h-6 mr-2 md:mr-3" />
//             <div>
//               APPSTORE
//             </div>
//           </a>

//           {/* Google Play Button */}
//           <a
//             href="#"
//             className="inline-flex items-center justify-center w-48 h-12 md:w-56 md:h-14 bg-yellow-500 text-black font-medium rounded-lg shadow-md hover:bg-yellow-600 transition-colors text-center"
//           >
//             <FontAwesomeIcon icon={faGooglePlay} className="w-5 h-5 md:w-6 md:h-6 mr-2 md:mr-3" />
//             <div>
//               GOOGLE PLAY
//             </div>
//           </a>
//         </div>
        
//         {/* Descriptive Text */}
//         <p className="mt-6 md:mt-8 text-gray-400 text-sm md:text-[20px] leading-relaxed max-w-md md:max-w-3xl lg:max-w-4xl mx-auto text-center px-4 md:px-0">
//           WahSmartDeals (a.k.a Wah!) is dedicated to making everyday savings effortless for
//           customers while driving growth for businesses. From daily essentials like your morning
//           coffee, to home services, beauty care, boutique shopping, and dining, Wah! brings you
//           a variety of deals to fit your lifestyle needs. By combining monthly 10x cash rewards 
//           and exclusive offers into one app, Wah! creates a win-win for everyone.
//         </p>

//         {/* Bottom Line */}
//         <hr className="mt-6 md:mt-8 border-t border-gray-600 w-3/4 md:w-full mx-auto" />
//       </div>
      
//     </section>
//   );
// }

// export default Home;


// src/pages/Home.js

// src/pages/Home.js

// src/pages/Home.js

import React, { useState, useEffect } from 'react';
import bgHero from '../assets/bg-hero.png';
import deviceHero from '../assets/device-hero.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';

function Home() {
  const customerMessages = [
    "Deal Navigators to best savings",
    "Negotiated Bulk Discounted Rates",
    "10x guaranteed cash rewards every month"
  ];

  const vendorMessages = [
    "Boost your Brand Awareness at ",
    "Acquire More Customers at ",
    "Drive Sales & Revenue at "
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isCustomerMessage, setIsCustomerMessage] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % 3);

      // Toggle between Customer and Vendor messages
      if (currentMessageIndex === 2) {
        setIsCustomerMessage((prev) => !prev);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentMessageIndex]);

  const messages = isCustomerMessage ? customerMessages : vendorMessages;

  return (
    <section className="bg-black text-white font-poppins">
      
      {/* Main Hero Section */}
      <div 
        className="relative min-h-screen flex items-center"
        style={{
          backgroundImage: `url(${bgHero})`,
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="container mx-auto px-4 md:px-12 lg:px-16 flex flex-col items-center md:flex-row">
          
          {/* Left Section - Text */}
          <div className="flex-1 text-center md:text-left md:pr-12 mt-6 md:mt-0">
            <h1 className="text-[32px] md:text-6xl font-bold leading-tight mb-2 transition-opacity duration-1000 ease-in-out">
              <span className="text-[#F7930D] text-[32px] md:text-7xl">
                {isCustomerMessage ? "Customers" : "Vendors"}
              </span><br />
              <span className="text-white">
                {messages[currentMessageIndex]}
                {!isCustomerMessage && (
                  <span className="text-[#F7930D]">NO COST</span>
                )}
              </span>
            </h1>
          </div>
          
          {/* Right Section - Device Image (hidden on mobile) */}
          <div className="flex-10 relative mt-4 md:mt-0 flex justify-center md:justify-end hidden md:block">
            <img
              src={deviceHero}
              alt="Device Hero"
              className="relative h-[550px]"
            />
          </div>
        </div>
      </div>

      {/* App Store Section */}
      <div className="container mx-auto px-4 md:px-12 lg:px-16 py-8 md:py-12 text-center flex flex-col items-center">
        <p className="text-sm md:text-lg font-semibold mb-4 md:mb-6 text-gray-300">
          Everything included for <span className="font-bold text-white">Android & iOS</span>
        </p>
        
        <div className="flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-4">
          {/* App Store Button */}
          <a
            href="#"
            className="inline-flex items-center justify-center w-48 h-12 md:w-56 md:h-14 bg-[#F7930D] text-black font-medium rounded-lg shadow-md hover:bg-[#F7930D] transition-colors text-center"
          >
            <FontAwesomeIcon icon={faApple} className="w-5 h-5 md:w-6 md:h-6 mr-2 md:mr-3" />
            <div>
              APPSTORE
            </div>
          </a>

          {/* Google Play Button */}
          <a
            href="#"
            className="inline-flex items-center justify-center w-48 h-12 md:w-56 md:h-14 bg-[#F7930D] text-black font-medium rounded-lg shadow-md hover:bg-[#F7930D] transition-colors text-center"
          >
            <FontAwesomeIcon icon={faGooglePlay} className="w-5 h-5 md:w-6 md:h-6 mr-2 md:mr-3" />
            <div>
              GOOGLE PLAY
            </div>
          </a>
        </div>
        
        {/* Descriptive Text */}
        <p className="mt-6 md:mt-8 text-gray-400 text-sm md:text-[20px] leading-relaxed max-w-md md:max-w-3xl lg:max-w-4xl mx-auto text-center px-4 md:px-0">
          WahSmartDeals (a.k.a Wah!) is dedicated to making everyday savings effortless for
          customers while driving growth for businesses. From daily essentials like your morning
          coffee, to home services, beauty care, boutique shopping, and dining, Wah! brings you
          a variety of deals to fit your lifestyle needs. By combining monthly 10x cash rewards 
          and exclusive offers into one app, Wah! creates a win-win for everyone.
        </p>

        {/* Bottom Line */}
        <hr className="mt-6 md:mt-8 border-t border-gray-600 w-3/4 md:w-full mx-auto" />
      </div>
      
    </section>
  );
}

export default Home;
