// src/pages/Features.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faGift, faHandshake, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import referAFriend from '../assets/refer-a-friend.png';

function Features() {
  return (
    <section className="bg-black text-white font-poppins py-16">
      <div className="container mx-auto px-6 md:px-12 lg:px-16 text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
          Why Choose <span className="text-[#F7930D]">WahSmartdeals?</span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          {/* Feature 1 */}
          <div className="bg-gray-800 p-8 rounded-lg text-center flex flex-col items-center h-64 shadow-md">
            <FontAwesomeIcon icon={faDollarSign} className="text-[#F7930D] text-4xl mb-4" />
            <h3 className="text-xl font-semibold mb-2">Guaranteed 10x Cash Rewards Every Month</h3>
            <p className="text-gray-400 text-sm leading-relaxed">
              Unlock 10x value of your subscription in cash rewards, every month!
            </p>
          </div>

          {/* Feature 2 */}
          <div className="bg-gray-800 p-8 rounded-lg text-center flex flex-col items-center h-64 shadow-md">
            <FontAwesomeIcon icon={faGift} className="text-[#F7930D] text-4xl mb-4" />
            <h3 className="text-xl font-semibold mb-2">Exclusive Vendor Deals</h3>
            <p className="text-gray-400 text-sm leading-relaxed">
              Access deals from a wide range of top vendors and save on every purchase.
            </p>
          </div>

          {/* Feature 3 */}
          <div className="bg-gray-800 p-8 rounded-lg text-center flex flex-col items-center h-64 shadow-md">
            <FontAwesomeIcon icon={faHandshake} className="text-[#F7930D] text-4xl mb-4" />
            <h3 className="text-xl font-semibold mb-2">Simple and Seamless Coupon Redemption</h3>
            <p className="text-gray-400 text-sm leading-relaxed">
              Customers can easily generate digital coupons and redeem them without hassle.
            </p>
          </div>

          {/* Feature 4 */}
          <div className="bg-gray-800 p-8 rounded-lg text-center flex flex-col items-center h-64 shadow-md">
            <FontAwesomeIcon icon={faPeopleArrows} className="text-[#F7930D] text-4xl mb-4" />
            <h3 className="text-xl font-semibold mb-2">Referral Bonuses</h3>
            <p className="text-gray-400 text-sm leading-relaxed">
              Earn more cash rewards when you invite friends—get extra rewards with every successful referral.
            </p>
          </div>
        </div>

        {/* Centered Image */}
        <div className="flex justify-center">
          <img
            src={referAFriend}
            alt="Refer a Friend"
            className="mx-auto max-w-full max-h-screen"
          />
        </div>
      </div>
    </section>
  );
}

export default Features;
